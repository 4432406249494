<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <component
                :is="getFormieComponent('input')"
                v-model="value"
                v-bind="getField(field).getInputBindings()"
                type="tel"
                autocomplete="tel"
            />
        </component>
    </div>
</template>

<script setup>
defineProps({
    field: {
        type: Object,
        required: true,
    }
});

const value = defineModel({ type: String, required: true });
</script>
